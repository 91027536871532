<template>
	<div>
		<b-card
			title="Blogily"
		>
			Blogily
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
} from 'bootstrap-vue'
import { auth } from '@/firebase.config'

export default {
	components: {
		BCard,
	},
	created() {
		console.log(auth)
	},
}
</script>

<style>

</style>
